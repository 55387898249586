import revive_payload_client_Wfmy9ffNap from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_yCHm4mp2UB from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WE8P2M9Wfh from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ab6sRa5Pgg from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tH1uTUajcV from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_87uyU0CwhN from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_xf08Gs2AQr from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8oCExFjJTY from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "D:/Github/oxbull-web-5-edge/.nuxt/components.plugin.mjs";
import prefetch_client_Gq72aSAQdX from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_q6lk0gEk5x from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_sass@1.83.0_tt2rve6fuacmolbpxtvcdztlcy/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_e9zjBgE84H from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_sass@1.83.0_tt2rve6fuacmolbpxtvcdztlcy/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_qILoCydJpV from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.29.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import slideovers_6OqvgS69f1 from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_qrcode@1.5.3_rollup@4.29.1__uacrm56qvsqburtesamxlat2e4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_9cf8NXjIVT from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_qrcode@1.5.3_rollup@4.29.1__uacrm56qvsqburtesamxlat2e4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_6RXhmtEa5Q from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@nuxt+ui@2.20.0_change-case@5.4.4_idb-keyval@6.2.1_magicast@0.3.5_qrcode@1.5.3_rollup@4.29.1__uacrm56qvsqburtesamxlat2e4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_HpZWMCBLbA from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_ILvYXvS3XR from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.29.1_vite@5.4.11_@types+node@22.10.2_sass@1.83.0_te_4i6aipw3reo32y3ekm7d7zuxpu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "D:/Github/oxbull-web-5-edge/.nuxt/floating-vue.mjs";
import plugin_client_hWe47I5QNb from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.7.2_/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_wofILYXtuU from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@hypernym+nuxt-anime@2.1.1/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import plugin_QpwGmIm4UL from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typesc_icz4ul6ywmtdjxvqpabl34m57a/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import calendar_client_YJGsTbn2VI from "D:/Github/oxbull-web-5-edge/plugins/calendar.client.js";
import content_GArnI6Y6ho from "D:/Github/oxbull-web-5-edge/plugins/content.js";
import vconsole_client_Zu3Hk7iamm from "D:/Github/oxbull-web-5-edge/plugins/vconsole.client.js";
import wallet_client_dYVbaY1ZcL from "D:/Github/oxbull-web-5-edge/plugins/wallet.client.js";
export default [
  revive_payload_client_Wfmy9ffNap,
  unhead_yCHm4mp2UB,
  router_WE8P2M9Wfh,
  payload_client_ab6sRa5Pgg,
  navigation_repaint_client_tH1uTUajcV,
  check_outdated_build_client_87uyU0CwhN,
  chunk_reload_client_xf08Gs2AQr,
  plugin_vue3_8oCExFjJTY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Gq72aSAQdX,
  pwa_icons_q6lk0gEk5x,
  pwa_client_e9zjBgE84H,
  nuxt_plugin_qILoCydJpV,
  slideovers_6OqvgS69f1,
  modals_9cf8NXjIVT,
  colors_6RXhmtEa5Q,
  plugin_client_HpZWMCBLbA,
  plugin_ILvYXvS3XR,
  floating_vue_EIcJ7xiw0h,
  plugin_client_hWe47I5QNb,
  plugin_wofILYXtuU,
  plugin_QpwGmIm4UL,
  calendar_client_YJGsTbn2VI,
  content_GArnI6Y6ho,
  vconsole_client_Zu3Hk7iamm,
  wallet_client_dYVbaY1ZcL
]