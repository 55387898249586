import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client/core";
import { useQuery, useMutation, provideApolloClient } from "@vue/apollo-composable";

import { setContext } from "@apollo/client/link/context";
import { useUserStore } from "~/stores/user.js";
// import { ConsoleLog } from "@openreplay/tracker/lib/app/messages.gen";

// setuplinks

const getClients = () => {

    const cache = new InMemoryCache();

    const { $accessToken } = useNuxtApp()

    const userStore = useUserStore()

    const httpLink = new HttpLink({
        uri: 'https://gql-gcp.oxblab.com/graphql-protected',
        // uri: 'http://localhost:8080/graphql-protected',
        credentials: "include",
    });

    const publicHttpLink = new HttpLink({
        uri: 'https://gql-gcp.oxblab.com/graphql',
        // uri: 'http://localhost:8080/graphql',
        credentials: "include",
    });


    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        return {
            headers: {
                ...headers,
                authorization: $accessToken.value ? `Bearer ${$accessToken.value}` : "",
            },
        };
    });

    const apolloPrivateClient = new ApolloClient({
        link: ApolloLink.from([authLink, httpLink]),
        cache,
    });

    const apolloPublicClient = new ApolloClient({
        link: publicHttpLink,
        cache,
    });

    return {
        apolloPrivateClient,
        apolloPublicClient
    }
}

const query = (_query, _variable, isPublic) => {

    const { apolloPrivateClient, apolloPublicClient } = getClients()

    return new Promise((resolve) => {
        let resp;
        if (isPublic) {
            resp = provideApolloClient(apolloPublicClient)(() => useQuery(_query, _variable));
        } else {
            resp = provideApolloClient(apolloPrivateClient)(() => useQuery(_query, _variable));
        }

        const { onResult, onError, result, loading } = resp;

        if (!loading.value) {
            resolve(result.value);
        }

        onResult((queryResult) => {
            if (queryResult.data) resolve(queryResult?.data);
        });

        onError((error) => {
            console.error(error, _query);
            resolve(false);
        });
    });
};

const queryNoCache = (_query, _variable, isPublic) => {

    const { apolloPrivateClient, apolloPublicClient } = getClients()

    return new Promise((resolve, reject) => {
        let resp;
        if (isPublic) {
            resp = provideApolloClient(apolloPublicClient)(() =>
                useQuery(_query, _variable, {
                    fetchPolicy: "no-cache",
                })
            );
        } else {
            resp = provideApolloClient(apolloPrivateClient)(() =>
                useQuery(_query, _variable, {
                    fetchPolicy: "no-cache",
                })
            );
        }

        const { onResult, onError, result, loading } = resp;

        if (!loading.value) {
            resolve(result.value);
        }

        onResult((queryResult) => {
            resolve(queryResult?.data);
        });

        onError((error) => {
            //console.log(143, error.graphQLErrors)
            //console.log(error, _query)
            reject(error);
            //resolve(false)
        });
    });
};

const mutate = (_query, _variable, _refetchQuery, _clientId) => {

    const { apolloPrivateClient, apolloPublicClient } = getClients()

    return new Promise((resolve) => {
        const { mutate, onDone, onError } = provideApolloClient(apolloPrivateClient)(() => useMutation(_query, { variables: _variable, refetchQueries: _refetchQuery, clientId: _clientId }));

        mutate(_variable, {});

        onDone((queryResult) => {
            resolve(queryResult.data);
        });

        onError((error) => {
            console.error(error);
            resolve(false);
        });
    });
};

export {
    query,
    queryNoCache,
    mutate
}