import routerOptions0 from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "D:/Github/oxbull-web-5-edge/app/router.options.js";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}