import validate from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "D:/Github/oxbull-web-5-edge/middleware/redirect.global.ts";
import manifest_45route_45rule from "D:/Github/oxbull-web-5-edge/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_bufferutil@4.0.8_db0@0.2.1_idb-keyva_tmapoidi5qpsmez5ts2bfwo2h4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  account: () => import("D:/Github/oxbull-web-5-edge/middleware/account.ts"),
  app: () => import("D:/Github/oxbull-web-5-edge/middleware/app.ts"),
  claim: () => import("D:/Github/oxbull-web-5-edge/middleware/claim.ts"),
  landing: () => import("D:/Github/oxbull-web-5-edge/middleware/landing.js")
}