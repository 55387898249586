
export default { 
  scrollBehavior(to, from, savedPosition) {

    // ignore if to and from is both on project

    console.log('to', to.name)

    let whitelisted = ['project','blink']

    if (whitelisted.includes(to.name) || whitelisted.includes(from.name)) {
      return savedPosition
    }else {

      if (savedPosition) {
        return savedPosition
      } else {
        return { 
          top: 0,
          behavior: 'smooth',
        }
      }

    }

  }
}