import { default as accountSzeZ3UYyI0Meta } from "D:/Github/oxbull-web-5-edge/pages/app/account.vue?macro=true";
import { default as _91project_id_93zlRgTXNnSoMeta } from "D:/Github/oxbull-web-5-edge/pages/app/blink/[project_id].vue?macro=true";
import { default as calendarNDym6imKyCMeta } from "D:/Github/oxbull-web-5-edge/pages/app/calendar.vue?macro=true";
import { default as claimSCHqTzZUjNMeta } from "D:/Github/oxbull-web-5-edge/pages/app/claim.vue?macro=true";
import { default as indexbJT1ZDz84xMeta } from "D:/Github/oxbull-web-5-edge/pages/app/index.vue?macro=true";
import { default as inoPBMePalbf5Meta } from "D:/Github/oxbull-web-5-edge/pages/app/ino.vue?macro=true";
import { default as kwai_45partial_45migrate7CfXJyHL26Meta } from "D:/Github/oxbull-web-5-edge/pages/app/kwai-partial-migrate.vue?macro=true";
import { default as otcuFKwxsdQtnMeta } from "D:/Github/oxbull-web-5-edge/pages/app/otc.vue?macro=true";
import { default as policyZ5qUpRHRedMeta } from "D:/Github/oxbull-web-5-edge/pages/app/policy.vue?macro=true";
import { default as private_45sale_45pass7jbbACqJ1BMeta } from "D:/Github/oxbull-web-5-edge/pages/app/private-sale-pass.vue?macro=true";
import { default as _91project_id_934f5vSEfkW6Meta } from "D:/Github/oxbull-web-5-edge/pages/app/private/[project_id].vue?macro=true";
import { default as _91project_id_93POpFyxGmbhMeta } from "D:/Github/oxbull-web-5-edge/pages/app/project/[project_id].vue?macro=true";
import { default as stakesoyhad8OJrMeta } from "D:/Github/oxbull-web-5-edge/pages/app/stake.vue?macro=true";
import { default as tg_45linkjnl6umsrRXMeta } from "D:/Github/oxbull-web-5-edge/pages/app/tg-link.vue?macro=true";
import { default as giftncQY4p4YYhMeta } from "D:/Github/oxbull-web-5-edge/pages/gift.vue?macro=true";
import { default as hall_45of_45fame65Zm9XUhikMeta } from "D:/Github/oxbull-web-5-edge/pages/hall-of-fame.vue?macro=true";
import { default as index_copyqU4MdYBrlKMeta } from "D:/Github/oxbull-web-5-edge/pages/index_copy.vue?macro=true";
import { default as index3lPTciW1cEMeta } from "D:/Github/oxbull-web-5-edge/pages/index.vue?macro=true";
import { default as privacyYn7XhEGqWRMeta } from "D:/Github/oxbull-web-5-edge/pages/privacy.vue?macro=true";
export default [
  {
    name: "account",
    path: "/app/account",
    meta: accountSzeZ3UYyI0Meta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/account.vue")
  },
  {
    name: "blink",
    path: "/app/blink/:project_id()",
    meta: _91project_id_93zlRgTXNnSoMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/blink/[project_id].vue")
  },
  {
    name: "calendar",
    path: "/app/calendar",
    meta: calendarNDym6imKyCMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/calendar.vue")
  },
  {
    name: "claim",
    path: "/app/claim",
    meta: claimSCHqTzZUjNMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/claim.vue")
  },
  {
    name: "app",
    path: "/app",
    meta: indexbJT1ZDz84xMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/index.vue")
  },
  {
    name: "ino",
    path: "/app/ino",
    meta: inoPBMePalbf5Meta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/ino.vue")
  },
  {
    name: "kwai-partial-migrate",
    path: "/app/kwai-partial-migrate",
    meta: kwai_45partial_45migrate7CfXJyHL26Meta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/kwai-partial-migrate.vue")
  },
  {
    name: "otc",
    path: "/app/otc",
    meta: otcuFKwxsdQtnMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/otc.vue")
  },
  {
    name: "policy",
    path: "/app/policy",
    meta: policyZ5qUpRHRedMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/policy.vue")
  },
  {
    name: "privateSalePass",
    path: "/app/private-sale-pass",
    meta: private_45sale_45pass7jbbACqJ1BMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/private-sale-pass.vue")
  },
  {
    name: "private",
    path: "/app/private/:project_id()",
    meta: _91project_id_934f5vSEfkW6Meta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/private/[project_id].vue")
  },
  {
    name: "project",
    path: "/app/project/:project_id()",
    meta: _91project_id_93POpFyxGmbhMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/project/[project_id].vue")
  },
  {
    name: "stake",
    path: "/app/stake",
    meta: stakesoyhad8OJrMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/stake.vue")
  },
  {
    name: "app-tg-link",
    path: "/app/tg-link",
    component: () => import("D:/Github/oxbull-web-5-edge/pages/app/tg-link.vue")
  },
  {
    name: "gift",
    path: "/gift",
    component: () => import("D:/Github/oxbull-web-5-edge/pages/gift.vue")
  },
  {
    name: "hall-of-fame",
    path: "/hall-of-fame",
    component: () => import("D:/Github/oxbull-web-5-edge/pages/hall-of-fame.vue")
  },
  {
    name: "home",
    path: "/index_copy",
    meta: index_copyqU4MdYBrlKMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/index_copy.vue")
  },
  {
    name: "home",
    path: "/",
    meta: index3lPTciW1cEMeta || {},
    component: () => import("D:/Github/oxbull-web-5-edge/pages/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("D:/Github/oxbull-web-5-edge/pages/privacy.vue")
  }
]